import React from 'react'

function Suggestion() {
  return (
    <>
      <p>Suggestion</p>
      <p>Feel free to add any suggestions</p>
    </>
  );
}

export default Suggestion