import React from "react"
import "./404.sass"

const PageNotFound = () => {
	return (
		<div className="bouncing-div">404 Page Not Found</div>
	)
}

export default PageNotFound
